import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { useClientConfigProvider } from '../../../../../components/ClientConfigProvider';
import { useSchdeuleProvider } from '../../../../../components/SchdeuleProvider';
import { smoothScrollBySteps } from '../../../../../utils/smooth-scroll';
import { ScheduledTag } from '../../../components/scheduled-tag/ScheduledTag';
import { scheduleScreenMessages } from '../../../messages';
import { useSchdeuleConfigProvider } from '../../../../../components/ScheduleConfigProvider';
import { AnimatedSlotData } from '../../animations/animated-slot-data/AnimatedSlotData';
import { useScrollableListProvider } from '../../../../../components/page-scroll-container/providers/ScrollableListProvider';
import './SchedulerSlotList.scss';

export const SchedulerSlotList = () => {
	const { availableSlots, scheduleItemData, isScheduleLoading } = useSchdeuleProvider();
	const { wrapperRef } = useScrollableListProvider();
	const [initLoading, setInitLoading] = useState(true);
	const {
		hostIdForScheduling,
		currentDay,
		selectedSlot, selectSlot
	} = useSchdeuleConfigProvider();
	const contentRef = useRef(null);
	const selectionAnnouncementRef = useRef(null);
	const { timezone } = useClientConfigProvider();
	const [slotsForPage, setSlotsForPage] = useState([]);
	const [preselected, setPreselected] = useState(false);
	const isSlotScheduled = (dataObject) => {
		let isSlotScheduled = '';
		if (scheduleItemData) {
			const scheduledTimeSlot = new Date(scheduleItemData.startTimestamp);
			if (scheduleItemData) {
				isSlotScheduled = scheduledTimeSlot.getTime() === dataObject.getTime();
			}
		}
		return isSlotScheduled;
	}

	useEffect(() => {
		if (availableSlots) {
			setSlotsForPage(availableSlots.map(item => ({
				...item,
				slots: item.slots.map((slot => ({
					...slot,
					isScheduled: scheduleItemData && isSlotScheduled(slot.timeSlot),
					isSlotSelectionAvailable: scheduleItemData && isSlotSelectionAvailable(slot.timeSlot)
				})))
			})))
		}
	}, [availableSlots, scheduleItemData])

	const isDateSelected = (dateObject) =>
		selectedSlot &&
		selectedSlot.timeSlot instanceof Date &&
		dateObject.getTime() === selectedSlot.timeSlot.getTime();

	const isSlotSelectionAvailable = (dataObject) => {
		let isSlotSelectionAvailable = '';
		if (scheduleItemData) {
			const scheduledTimeSlot = new Date(scheduleItemData.startTimestamp);
			let isEventHostsEqualExpression = true;
			if (scheduleItemData) {
				if (hostIdForScheduling) {
					if (scheduleItemData.hostId) {
						isEventHostsEqualExpression = scheduleItemData.hostId === hostIdForScheduling;
					} else {
						isEventHostsEqualExpression = false;
					}
				} else {
					if (scheduleItemData.hostId) {
						isEventHostsEqualExpression = false;
					}
				}
			}
			isSlotSelectionAvailable = scheduledTimeSlot.getTime() === dataObject.getTime() && !isEventHostsEqualExpression;
		}
		return isSlotSelectionAvailable;
	}

	const formatTime = (dateObject) => new Intl.DateTimeFormat([], {
		hour: 'numeric',
		minute: 'numeric',
	}).format(dateObject);

	const formatZimezone = () => {
		return timezone || 'local';
	}

	useEffect(() => {
		if (wrapperRef.current &&
			contentRef.current &&
			currentDay !== null &&
			slotsForPage.length > 0 &&
			!preselected) {
			setPreselected(true);
			let index = 0;
			if (scheduleItemData) {
				if (selectedSlot) {
					index = slotsForPage[currentDay].slots.findIndex((item => isDateSelected(item.timeSlot)))
				} else {
					index = slotsForPage[currentDay].slots.findIndex((item => item.isScheduled))
				}
			} else if (selectedSlot) {
				index = slotsForPage[currentDay].slots.findIndex((item => isDateSelected(item.timeSlot)))
			}
			if (index > 0) {
				const targetEl = contentRef.current.childNodes.item(index + 1);
				if (targetEl && targetEl.offsetTop > 0) {
					smoothScrollBySteps(
						wrapperRef.current,
						wrapperRef.current.scrollTop,
						targetEl.offsetTop - 20
					).then(() => { })
				}
			}
		}
	}, [slotsForPage,
		contentRef.current,
		wrapperRef.current,
		scheduleItemData,
		currentDay,
		selectedSlot,
		preselected])

	useEffect(() => {
		if (slotsForPage.length > 0 && slotsForPage[currentDay] && slotsForPage[currentDay].slots && slotsForPage[currentDay].slots.length > 0 && currentDay !== 0) {
			setInitLoading(false);
		}
	}, [slotsForPage, currentDay])

	const renderSlotList = () => {
		if (slotsForPage.length > 0 && slotsForPage[currentDay] && slotsForPage[currentDay].slots && slotsForPage[currentDay].slots.length > 0) {
			return <>
				<li className='slots-timezone'><FormattedMessage {...scheduleScreenMessages.nouns_timezone} />: {formatZimezone()}</li>
				{slotsForPage[currentDay].slots.map((session) =>
					<li
						key={session.timeSlot}
						time-slot-value={formatTime(session.timeSlot)}
						className={`time-slot ${session.isAvailable ? '' : 'unavailable '}
					 ${isDateSelected(session.timeSlot) ? 'selected' : ''}
					 ${session.isScheduled ? ` scheduled${!selectedSlot ? ' checked' : ''}` : ''}
					 ${session.isSlotSelectionAvailable ? ' select-allowed' : ''}
					 ${initLoading ? ' initial' : ''}`}
						onClick={() => selectSlot(session)}
						aria-label={`${formatTime(session.timeSlot)} ${isDateSelected(session.timeSlot) ? 'selected' : ''}`}
						tabindex={0}
						aria-selected={isDateSelected(session.timeSlot)}
						>
						<p className='time-slot-value'>{formatTime(session.timeSlot)}</p>
						<div className='scheduled-tag-wrapper'>
							<ScheduledTag shadow={false} />
						</div>
					</li>)}
			</>
		} else {
			return <div className='no-slots-message-container'>
				<p><FormattedMessage {...scheduleScreenMessages.no_available_slots} /></p>
			</div>
		}
	}

	return (
		<ul ref={contentRef} className='select-host-list'>
			{isScheduleLoading ?
				<AnimatedSlotData /> :
				renderSlotList()}
		</ul>
	)
}
